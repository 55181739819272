<template>
  <div>
    <el-button type="primary" @click="save">保存</el-button>
    <el-tabs type="border-card" style="margin-top:12px;" value="first">
      <el-tab-pane label="基础信息" name="first">
        <el-form :model="company" label-width="120px">
          <el-form-item label="公司英文名称">
            <el-input v-model="company.CompanyNameEng"></el-input>
          </el-form-item>
          <el-form-item label="公司中文名称">
            <el-input v-model="company.CompanyNameChn"></el-input>
          </el-form-item>
          <el-form-item label="公司logo">
            <el-input v-model="company.CompanyLogo"></el-input>
          </el-form-item>
          <el-form-item label="公司英文介绍">
            <el-input type="textarea" v-model="company.CompanyBriefEng"></el-input>
          </el-form-item>
          <el-form-item label="公司中文介绍">
            <el-input type="textarea" v-model="company.CompanyBriefChn"></el-input>
          </el-form-item>
          <el-form-item label="公司英文网址">
            <el-input v-model="company.WebSiteEng"></el-input>
          </el-form-item>
          <el-form-item label="公司中文网址">
            <el-input v-model="company.WebSiteChn"></el-input>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="轮播图片及logo" name="second">
        <el-row>
          <el-col :span="12">
            <el-form :model="company" label-width="120px">
              <el-form-item label="轮播图图片">
                <el-upload
                  :file-list="crouselpics"
                  :action="$store.state.baseurl+'/api/file/upload'"
                  list-type="picture"
                  :on-success="uploadedCarouselPics"
                  :on-remove="handleRemoveCarouselPics"
                  :on-error="handleError"
                  :headers="headerObj"
                >
                  <el-button size="small" type="primary">点击上传</el-button>
                  <div slot="tip">只能上传jpg/png/bmp文件，分辨率为1680*400dpi且不超过500kb，文件名不能有空格</div>
                </el-upload>
              </el-form-item>
            </el-form>
          </el-col>
          <el-col :span="12">
            <el-form :model="company" label-width="120px">
              <el-form-item label="公司logo">
                <el-upload
                  :file-list="logopics"
                  :action="$store.state.baseurl+'/api/file/upload'"
                  list-type="picture"
                  :on-success="uploadedLogoPics"
                  :on-remove="handleRemoveLogoPics"
                  :on-error="handleError"
                  :headers="headerObj"
                >
                  <el-button size="small" type="primary">点击上传</el-button>
                  <div slot="tip">只能上传jpg/png/bmp文件，高度不要超过50px 且不超过500kb，文件名不能有空格</div>
                </el-upload>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="首页显示的产品" name="third">
        <el-table
          ref="productsTable"
          :data="products"
          tooltip-effect="dark"
          style="width: 100%"
          @select="handleSelection"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column prop="ProductID" label="产品编号" width="200"></el-table-column>
          <el-table-column prop="ProductNameChn" label="产品名称" width="200"></el-table-column>
          <el-table-column prop="ProductNameEng" label="产品英文名称" width="240"></el-table-column>
          <el-table-column prop="ProductSizeChn" label="产品规格" width="200"></el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
export default {
  data() {
    return {
      company: {},
      crouselpics: [],
      deleteimgs: [],
      products: [],
      logopics: [],
      headerObj:{
        Authorization:window.localStorage.getItem('token')
      }
    }
  },
  created() {
    this.$http.get('/company/getcompany').then(res => {
      this.company = res.data.data
      //获取轮播图片缩略图
      var p = []
      if (
        this.company.CarouselPics != null &&
        this.company.CarouselPics != undefined &&
        this.company.CarouselPics != ''
      ) {
        this.company.CarouselPics.split(' ').forEach(element => {
          var pimg = {}
          pimg.name = element
          pimg.url = this.$store.state.baseurl + '/Images/' + element
          p.push(pimg)
        })
      }
      this.crouselpics = p

      //获取公司logo缩略图
      var lp = []
      if (
        this.company.CompanyLogo != null &&
        this.company.CompanyLogo != undefined &&
        this.company.CompanyLogo != ''
      ) {
        this.company.CompanyLogo.split(' ').forEach(element => {
          var pimg = {}
          pimg.name = element
          pimg.url = this.$store.state.baseurl + '/Images/' + element
          lp.push(pimg)
        })
      }
      this.logopics = lp
    }).catch(err => {
          this.$message.error(err.response.data.Message + ',请重新登录')
          this.$router.push('/login')
        })

    this.$http
      .get('/product/GetAllProducts')
      .then(res => (this.products = res.data.data)).catch(err => {
          this.$message.error(err.response.data.Message + ',请重新登录')
          this.$router.push('/login')
        })
  },
  updated(){
    //设置默认选中有问题：必须在updated获取，在mounted或者created里面均失败，因为DOM尚未渲染
    this.checked()
  },
  methods: {
    checked() {
      var a = (this.company.GoodProductIDs || '').split(' ')
      a.forEach(e => {
        var index = this.products.findIndex(val => val.ProductID == e)
        this.$refs.productsTable.toggleRowSelection(this.products[index], true)
      })
    },
    save() {
      //把crouselpics里的文件名转成以空格分隔的字符串保存到company.CarouselPics中
      var imgurls = ''
      this.crouselpics.forEach(element => {
        imgurls += element.name + ' '
      })
      this.company.CarouselPics = imgurls.trim()

      //把logopics里的文件名转成以空格分隔的字符串保存到company.CompanyLogo中
      var logourls = ''
      this.logopics.forEach(element => {
        logourls += element.name + ' '
      })
      this.company.CompanyLogo = logourls.trim()

      this.$http
        .post('/company/editcompany', this.company)
        .then(res => {
          if (res.data.code != 200) {
            this.$message.error(res.data.errmsg)
          } else {
            //删除deleteimgs中的文件
            if (this.deleteimgs.length > 0) {
              this.$http.post('/file/delete', this.deleteimgs).then(res => {
                window.console.log(res)
              })
            }
            this.$message({
              message: '保存成功！',
              type: 'success'
            })
          }
        })
        .catch(err => {
          window.console.log(err.response)
          this.$message.error(err.response.data.Message + ',请重新登录')
          this.$router.push('/login')
        })
    },
    uploadedCarouselPics(res) {
      var pimg = {}
      pimg.name = res.data
      pimg.url = this.$store.state.baseurl + '/Images/' + pimg.name
      this.crouselpics.push(pimg) //这里也需要把上传的文件添加到数组的头部
    },
    uploadedLogoPics(res) {
      var pimg = {}
      pimg.name = res.data
      pimg.url = this.$store.state.baseurl + '/Images/' + pimg.name
      this.logopics.push(pimg)
    },
    handleRemoveCarouselPics(file) {
      //点击删除按钮时，把删除的 文件名保存到deleteimgs里面，当客户点击保存按钮时，从服务器上删除这些文件
      this.deleteimgs.push(file.name)
      //同时要从productimg里删除这个文件
      var index = this.crouselpics.findIndex(item => {
        if (item.name == file) {
          return true
        }
      })
      this.crouselpics.splice(index, 1)
    },
    handleRemoveLogoPics(file) {
      //点击删除按钮时，把删除的 文件名保存到deleteimgs里面，当客户点击保存按钮时，从服务器上删除这些文件
      this.deleteimgs.push(file.name)
      //同时要从productimg里删除这个文件
      var index = this.logopics.findIndex(item => {
        if (item.name == file) {
          return true
        }
      })
      this.logopics.splice(index, 1)
    },
    handleSelection(val) {
     
      var m = ''
      val.forEach(e => {
        m = m + ' ' + e.ProductID
      })
      this.company.GoodProductIDs = m.trim()
      //window.console.log(this.company)
    },
    
    handleError(err){
      window.console.log(err)
      this.$router.push('/login')
    }
  }
}
</script>